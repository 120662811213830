<template>
  <div class="main-page">
    <div v-if="showManager" class="admin-section">
      <span class="admin-link" @click="goToManager">{{ $t("manager") }}</span>
    </div>

    <div class="header">
      <div class="logo">
        <img src="../../public/logo.png" alt="Logo Image" />
      </div>
      <div class="user-info">
        <span v-if="isLoggedIn" class="link" @click="goToUserProfile">{{ nickname }}</span>
        <span v-else class="link" @click="goToLogin">{{ $t("login") }}</span>
      </div>
    </div>

    <div class="main-box">
      <!-- 제목 (5번 클릭 감지) -->
      <div class="logo-main"><img src="../../public/logo.png" alt="Logo Image" /></div>
      <div class="title">
        <div class="main-title" @click="handleWelcomeClick">Please select the service you want.</div>
        <div class="sub-title">If you don't see it, please contact us.</div>
      </div>
      <div class="button-group">
        <ul class="ul-list">
          <li>
            <button @click="goToVisit" class="button-main">
              <div class="btn-name">{{ $t("visitButton") }}</div>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" class="icon">
                <path
                  d="M5.46967 12.4697C5.17678 12.7626 5.17678 13.2374 5.46967 13.5303C5.76256 13.8232 6.23744 13.8232 6.53033 13.5303L5.46967 12.4697ZM11 8L11.5303 8.53033C11.8232 8.23744 11.8232 7.76256 11.5303 7.46967L11 8ZM6.53033 2.46967C6.23744 2.17678 5.76256 2.17678 5.46967 2.46967C5.17678 2.76256 5.17678 3.23744 5.46967 3.53033L6.53033 2.46967ZM6.53033 13.5303L11.5303 8.53033L10.4697 7.46967L5.46967 12.4697L6.53033 13.5303ZM11.5303 7.46967L6.53033 2.46967L5.46967 3.53033L10.4697 8.53033L11.5303 7.46967Z"
                ></path>
              </svg>
            </button>
          </li>
          <li>
            <button @click="goToProduct" class="button-main">
              <div class="btn-name">{{ $t("productButton") }}</div>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" class="icon">
                <path
                  d="M5.46967 12.4697C5.17678 12.7626 5.17678 13.2374 5.46967 13.5303C5.76256 13.8232 6.23744 13.8232 6.53033 13.5303L5.46967 12.4697ZM11 8L11.5303 8.53033C11.8232 8.23744 11.8232 7.76256 11.5303 7.46967L11 8ZM6.53033 2.46967C6.23744 2.17678 5.76256 2.17678 5.46967 2.46967C5.17678 2.76256 5.17678 3.23744 5.46967 3.53033L6.53033 2.46967ZM6.53033 13.5303L11.5303 8.53033L10.4697 7.46967L5.46967 12.4697L6.53033 13.5303ZM11.5303 7.46967L6.53033 2.46967L5.46967 3.53033L10.4697 8.53033L11.5303 7.46967Z"
                ></path>
              </svg>
            </button>
          </li>
        </ul>
      </div>
    </div>
    <!-- Footer -->
    <footer class="footer">
      <div class="footer-links">
        <a href="#">{{ $t("language") }}</a>
        <a @click="goToTerms">{{ $t("termOfService") }}</a>
        <a @click="goToPrivacy">{{ $t("privacyPolicy") }}</a>
        <a @click="goToFaq">{{ $t("faqSupport") }}</a>
      </div>
      <div class="footer-info">사업자 등록번호 : 814-87-03071<br />Copyright © [2004] (주) 무한시스템즈. All Rights Reserved</div>
    </footer>
  </div>
</template>

<script>
import { ref } from "vue";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import { doLogout } from "@/components/authentication/AuthService";

export default {
  name: "PageMain",
  setup() {
    const router = useRouter();
    const { locale } = useI18n();

    const isLoggedIn = ref(localStorage.getItem("LoggedIn") === "true");
    const nickname = ref(localStorage.getItem("Nickname"));
    const userType = ref(localStorage.getItem("UserType"));

    // 관리자 섹션 표시 여부와 클릭 횟수
    const showManager = ref(false);
    const welcomeClickCount = ref(0);

    // welcome 클릭 이벤트
    const handleWelcomeClick = () => {
      welcomeClickCount.value += 1;
      if (welcomeClickCount.value >= 5) {
        showManager.value = true; // 5번 클릭하면 관리자 섹션 표시
      }
    };

    const goToLogin = () => {
      router.push("/login");
    };

    const goToUserProfile = () => {
      router.push("/user-profile");
    };

    const goToManager = () => {
      const isLoggedIn = localStorage.getItem("LoggedIn") === "true";
      const userType = localStorage.getItem("UserType");

      if (isLoggedIn && userType === "manager") {
        router.push("/manager"); // 매니저로 로그인 상태
      } else {
        router.push("/login/manager"); // 로그인 필요
      }
    };

    const submitLogout = async () => {
      await doLogout();
      welcomeClickCount.value = 0;
      showManager.value = false;

      isLoggedIn.value = false;
      nickname.value = null;
      router.push("/"); // 홈 화면으로 이동
    };

    const goToVisit = () => {
      router.push("/visit");
    };

    const goToProduct = () => {
      if (isLoggedIn.value) {
        router.push("/product");
      } else {
        alert("로그인부터 진행해주세요.");
        router.push("/login"); // 로그인 페이지로 이동
      }
    };

    const goToTerms = () => {
      router.push("/terms-of-service");
    };

    const goToPrivacy = () => {
      router.push("/privacy-policy");
    };

    const goToFaq = () => {
      router.push("/faq-support");
    };

    const toggleLanguage = () => {
      locale.value = locale.value === "ko" ? "en" : "ko";
    };

    return { isLoggedIn, nickname, userType, showManager, handleWelcomeClick, goToLogin, goToUserProfile, goToManager, submitLogout, goToVisit, goToProduct, goToTerms, goToPrivacy, goToFaq, toggleLanguage };
  },
};
</script>

<style scoped>
.main-title {
  font-size: 24px;
  font-family: "GmarketSansBold";
  line-height: 1.25;
  word-break: keep-all;
  white-space: pre-wrap;
}

.sub-title {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.5;
  color: #868e96;
  word-break: keep-all;
  white-space: pre-wrap;
}

.title {
  margin-bottom: 20px;
}

.logo-main img {
  width: 150px;
}

.logo-main {
  text-align: center; /* 수평 가운데 정렬 */
  margin-bottom: 30px;
}

.logo img {
  width: 50px; /* 로고 크기 조정 */
  height: auto; /* 비율 유지 */
}

.main-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  position: relative;
  opacity: 1;
}

.ul-list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 8px;
}

.admin-section {
  position: fixed;
  top: 10px;
  left: 10px;
}

.admin-link {
  font-size: 22px;
  font-weight: bold;
  color: #ff4500; /* 관리자 색상 */
  cursor: pointer;
}

.button-main {
  display: flex;
  gap: 16px;
  padding: 16px 20px;
  background-color: white;
  box-shadow: 0px 2px 8px 0px #4950570f;
  border: 1px solid #f1f3f5;
  border-radius: 16px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.btn-name {
  color: #212529;
  font-size: 14px;
  font-family: "GmarketSansMedium";
}

.header {
  position: fixed; /* 화면 상단에 고정 */
  top: 0;
  left: 0;
  width: 100%; /* 전체 너비 사용 */
  display: flex; /* Flexbox로 배치 */
  justify-content: space-between; /* 좌우 끝으로 배치 */
  align-items: center; /* 세로 가운데 정렬 */
  padding: 10px; /*   내부 여백 */
  z-index: 1000; /* 다른 요소보다 위에 표시 */
}

.user-info {
  display: flex;
  align-items: center; /* 수직 가운데 정렬 */
}

.user-info .link {
  cursor: pointer;
  color: #007bff;
  font-weight: bold;
  font-size: 16px;
}

.user-info .separator {
  margin: 0 15px;
  color: #007bff;
  font-size: 22px;
}

.language-switch .language-button {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  font-weight: bold;
  color: #007bff;
  font-size: 22px;
  outline: none;
}

.button-group {
  display: flex;
  flex-direction: column;
}

button {
  display: flex;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}

.icon {
  width: 20px !important;
}

.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  text-align: center;
  background-color: #f8f9fa;
  padding: 10px 0;
  font-size: 14px;
  color: #666;
}

.footer-links {
  margin-bottom: 5px;
}

.footer-links a {
  margin: 0 10px;
  color: #007bff;
  text-decoration: none;
}

.footer-links a:hover {
  text-decoration: underline;
}

.footer-info {
  font-size: 12px;
  color: #666;
}
</style>
