<template>
  <div class="terms-of-service-page">
    <h1>{{ $t("termOfService") }}</h1>
    <div class="terms-content">
      <p>본 서비스 약관은 (주) 무한시스템즈가 제공하는 서비스와 관련하여 고객과 회사 간의 권리, 의무, 책임사항 및 기타 필요한 사항을 규정합니다.</p>
      <h2>1. 서비스 이용</h2>
      <p>사용자는 본 서비스를 이용함으로써 본 약관에 동의한 것으로 간주됩니다. 사용자는 서비스 이용 시 관련 법령을 준수해야 하며, 회사는 필요에 따라 서비스를 변경하거나 중단할 수 있습니다.</p>
      <h2>2. 개인정보 보호</h2>
      <p>
        회사는 사용자의 개인정보를 소중히 다루며, 관련 법령에 따라 적절히 보호 및 관리합니다. 자세한 내용은 <a @click="goToPrivacy">{{ $t("privacyPolicy") }}</a
        >를 참조하세요.
      </p>
      <h2>3. 책임 제한</h2>
      <p>회사는 서비스 이용 과정에서 발생할 수 있는 어떠한 손해에 대해서도 책임을 지지 않습니다. 단, 회사의 고의 또는 중대한 과실로 인한 손해는 제외됩니다.</p>
      <p>
        추가적인 질문이나 문의사항이 있을 경우 <a @click="goToFaq">{{ $t("faqSupport") }}</a> 페이지를 참조하세요.
      </p>
    </div>
  </div>
</template>

<script>
import { useRouter } from "vue-router";

export default {
  name: "TermsOfService",
  setup() {
    const router = useRouter();

    const goToPrivacy = () => {
      router.push("/privacy-policy");
    };

    const goToFaq = () => {
      router.push("/faq-support");
    };

    return {
      goToPrivacy,
      goToFaq,
    };
  },
};
</script>

<style scoped>
.terms-of-service-page {
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
  line-height: 1.6;
}

h1 {
  font-size: 28px;
  margin-bottom: 20px;
}

h2 {
  font-size: 20px;
  margin-top: 20px;
}

.terms-content p {
  margin-bottom: 15px;
}

a {
  color: #007bff;
  text-decoration: none;
  cursor: pointer;
}

a:hover {
  text-decoration: underline;
}
</style>
