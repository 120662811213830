<template>
  <div class="faq-support-page">
    <h1>{{ $t("faqSupport") }}</h1>
    <div class="faq-content">
      <h2>1. 회원가입은 어떻게 하나요?</h2>
      <p>회원가입은 로그인 화면에서 "회원가입" 버튼을 클릭하여 진행할 수 있습니다. 필요한 정보를 입력하고 제출하면 가입이 완료됩니다.</p>
      <h2>2. 비밀번호를 잊어버렸어요. 어떻게 해야 하나요?</h2>
      <p>로그인 화면에서 "비밀번호 찾기"를 클릭한 후 이메일을 입력하면 비밀번호를 재설정할 수 있는 링크를 받을 수 있습니다.</p>
      <h2>3. 서비스 이용 중 문제가 발생했어요. 누구에게 문의해야 하나요?</h2>
      <p>서비스 이용 중 문제가 발생하면, 아래의 고객 지원 정보를 통해 문의할 수 있습니다.</p>
      <ul>
        <li>이메일: support@company.com</li>
        <li>전화번호: 123-456-7890</li>
      </ul>
      <h2>4. 환불 정책은 어떻게 되나요?</h2>
      <p>
        서비스의 환불 정책은 <a @click="goToTerms">{{ $t("termOfService") }}</a
        >를 참조하세요.
      </p>
      <!-- 테스트 버튼 -->
      <button class="test-button" @click="handleTestClick">테스트 버튼</button>
    </div>
  </div>
</template>

<script>
import { useRouter } from "vue-router";
import apiService from "@/api/apiService";

export default {
  name: "FAQSupport",
  setup() {
    const router = useRouter();

    const goToTerms = () => {
      router.push("/terms-of-service");
    };

    const handleTestClick = async () => {
      try {
        // 테스트용 이메일 데이터
        const emailData = {
          name: "테스트 유저",
          email: localStorage.getItem("Email"),
          message: "이것은 테스트 이메일입니다.",
        };

        // apiService의 sendSupportEmail 호출
        const response = await apiService.sendSupportEmail(emailData);
        console.log("이메일 전송 성공:", response);
        alert("이메일 전송 성공!");
      } catch (error) {
        console.error("이메일 전송 실패:", error.message);
        alert("이메일 전송에 실패했습니다. 서버 로그를 확인하세요.");
      }
    };

    return {
      goToTerms,
      handleTestClick,
    };
  },
};
</script>

<style scoped>
.faq-support-page {
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
  line-height: 1.6;
}

h1 {
  font-size: 28px;
  margin-bottom: 20px;
}

h2 {
  font-size: 20px;
  margin-top: 20px;
}

.faq-content p {
  margin-bottom: 15px;
}

ul {
  list-style-type: disc;
  margin-left: 20px;
}

a {
  color: #007bff;
  text-decoration: none;
  cursor: pointer;
}

a:hover {
  text-decoration: underline;
}
</style>
