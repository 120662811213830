<template>
  <div>
    <h2 class="title">숙박 옵션</h2>
    <div class="stay-options-container">
      <div class="stay-options">
        <label v-for="(option, index) in stayOptions" :key="index" class="option">
          <input type="checkbox" :value="option.type" v-model="selectedTypes" @change="getAccommodation" />
          {{ option.name }}
        </label>
      </div>
    </div>

    <div class="data-list-container" v-if="stayDetails.length > 0">
      <h3>선택한 숙박 옵션 정보</h3>
      <ul class="data-list">
        <li v-for="(detail, index) in stayDetails" :key="index">
          <strong>{{ detail.name }}</strong> - {{ detail.address }} / {{ detail.price1 }}원
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import apiService from "@/api/apiService";

export default {
  name: "VisitAccommodation",
  setup() {
    const stayOptions = ref([
      { name: "호텔 5등급", type: "H5" },
      { name: "호텔 4등급", type: "H4" },
      { name: "호텔 3등급", type: "H3" },
      { name: "호텔 2등급", type: "H2" },
      { name: "호텔 1등급", type: "H1" },
      { name: "리조트", type: "RS" },
      { name: "모텔", type: "MT" },
      { name: "여관", type: "I1" },
      { name: "여인숙", type: "I2" },
      { name: "게스트하우스", type: "GH" },
      { name: "펜션", type: "PS" },
      { name: "캠핑카", type: "CP" },
      { name: "노숙", type: "BV" },
    ]);

    const selectedTypes = ref([]);
    const stayDetails = ref([]);

    const getAccommodation = async () => {
      try {
        console.log("현재 체크된 타입:", selectedTypes.value);

        const response = await apiService.getAccommodation({ types: selectedTypes.value });
        console.log("서버에서 받은 숙박 정보:", response);
        stayDetails.value = response.data;
      } catch (error) {
        console.error("숙박 정보를 가져오는 중 오류 발생:", error.message);
      }
    };

    return {
      stayOptions,
      selectedTypes,
      stayDetails,
      getAccommodation,
    };
  },
};
</script>

<style scoped>
.title {
  text-align: left;
  font-size: 24px;
  margin-bottom: 10px;
  padding-left: 20px;
}

.stay-options-container {
  display: flex;
  justify-content: flex-start;
  margin: 20px auto;
  width: 100%;
  padding-left: 20px;
}

.stay-options {
  display: grid;
  grid-template-columns: repeat(5, minmax(100px, 1fr));
  gap: 10px;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 800px;
}

.option {
  display: flex;
  align-items: center;
}

.option input {
  margin-right: 8px;
  transform: scale(1.2);
}

.data-list-container {
  margin-top: 20px;
  padding-left: 20px;
}

.data-list {
  list-style: none;
  padding: 0;
}

.data-list li {
  margin-bottom: 10px;
  padding: 8px;
  border-bottom: 1px solid #ddd;
}

.accommodation-detail {
  text-align: left;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin: 10px 0;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.accommodation-image {
  width: 100px;
  height: auto;
  margin-right: 10px;
}
</style>
