<template>
  <div class="quote-request-page">
    <!-- 상단 바 -->
    <div class="top-bar">
      <div class="user-info">
        <span v-if="isLoggedIn" class="link" @click="goToUserProfile">{{ nickname }}</span>
      </div>
    </div>

    <div class="title">
      <h1>Quote History</h1>
    </div>

    <table class="history-table">
      <thead>
        <tr>
          <th>No.</th>
          <th>{{ $t("date") }}</th>
          <th>{{ $t("status") }}</th>
          <th>{{ $t("productName") }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(request, index) in requests" :key="request.id_request" @click="navigateByStatus(request)" class="clickable-row">
          <td>{{ index + 1 }}</td>
          <td>{{ formatDate(request.date_request) }}</td>
          <td>
            <span :class="['status', getStatusClass(request.status)]">
              {{ getStatusText(request.status) }}
            </span>
          </td>
          <td>{{ request.product }}</td>
        </tr>
        <tr v-if="requests.length === 0">
          <td colspan="6" class="no-data">{{ $t("noRequests") }}</td>
        </tr>
      </tbody>
    </table>

    <div class="left-section">
      <!-- <button class="button-new" @click="goToRequest">{{ $t("new") }}</button> -->
      <button class="floating-button" @click="goToRequest">{{ $t("new") }}</button>
      <!--  <span class="available-count">{{ $t("available_count") }}: {{ available }}</span>  -->
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import apiService from "@/api/apiService";
import { doLogout } from "@/components/authentication/AuthService";

export default {
  name: "P_0Main",
  setup() {
    const router = useRouter();
    const { t } = useI18n();
    const requests = ref([]);
    const isLoggedIn = ref(localStorage.getItem("LoggedIn") === "true");
    const nickname = ref(localStorage.getItem("Nickname"));
    const available = ref(localStorage.getItem("Available") || "0");

    if (!isLoggedIn.value) {
      router.push("/login");
    }

    const goToUserProfile = () => {
      router.push("/user-profile");
    };

    const goToRequest = () => {
      router.push({ name: "P_1Request" }); // 페이지 이동
    };

    const checkUserType = () => {
      const userType = localStorage.getItem("UserType");
      console.log("@@ userType : ", userType);
      if (userType !== "customer") {
        alert("권한이 없습니다. 메인 페이지로 이동합니다.");
        doLogout();
        router.push("/");
        return false;
      }
      return true;
    };

    const getRequestList = async () => {
      if (!checkUserType()) return; // 권한 확인 후 요청 수행

      try {
        const userId = localStorage.getItem("UserId");
        if (!userId) {
          throw new Error("User ID not found.");
        }

        const userType = localStorage.getItem("UserType");
        if (!userType) {
          throw new Error("User Type not found.");
        }

        const data = await apiService.getRequestList({ userId, userType });
        requests.value = data;
      } catch (error) {
        console.error("Failed to fetch quote requests:", error);
      }
    };

    const formatDate = (date) => {
      const options = { month: "short", day: "numeric" }; // 월, 일
      return new Date(date).toLocaleDateString("en-US", options);
    };

    const getStatusClass = (status) => {
      switch (status) {
        case 1:
          return "issued";
        case 2:
          return "requested";
        case 3:
          return "paid";
        case 4:
          return "canceled";
        default:
          return "";
      }
    };

    const getStatusText = (status) => {
      const statusMap = {
        1: t("status_requested"),
        2: t("status_issued"),
        3: t("status_paid"),
        4: t("status_canceled"),
        5: t("status_expired"),
      };
      return statusMap[status] || t("unknown");
    };

    const navigateByStatus = (request) => {
      console.log("navigateByStatus request:", request);

      if (request.status === 1) {
        router.push({
          path: `/product/edit-request/${request.id_request}`,
          query: {
            product: request.product,
            quantity: request.quantity,
            brand: request.brand,
            referenceLink: request.link || "",
            hasImage: request.has_image ? 1 : 0,
            extraRequest: request.extra_request || "",
          },
        });
      } else if (request.status === 2) {
        router.push({
          name: "P_2Payment",
          params: { id_request: request.id_request },
        });
      } else {
        alert(t("unsupported_status"));
      }
    };

    onMounted(() => {
      getRequestList();
    });

    return { isLoggedIn, requests, nickname, available, goToRequest, goToUserProfile, formatDate, getStatusClass, getStatusText, navigateByStatus };
  },
};
</script>

<style scoped>
.quote-request-page {
  padding: 20px;
}

.title {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 0;
  margin-top: 0;
  min-height: 0;
}

.top-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.left-section {
  display: flex;
  align-items: center; /* New 버튼과 Available Count를 세로로 정렬 */
  flex-direction: row-reverse;
  margin-top: 20px;
}

.status {
  display: inline-block;
  padding: 4px 12px;
  border-radius: 16px; /* pill 모양 */
  font-size: 0.875em;
  font-weight: bold;
  text-align: center;
  min-width: 80px;
  color: #fff; /* 기본 텍스트 색상 */
}

.status.issued {
  background-color: #f39c12; /* 주황색 */
}

.status.requested {
  background-color: #007bff; /* 파란색 */
}

.status.rejected {
  background-color: #e74c3c; /* 빨간색 */
}

.status.in_progress {
  background-color: #0ac467; /* 초록색 */
}

/* 클릭 가능한 행 스타일 */
.clickable-row {
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.clickable-row:hover {
  background-color: #f9f9f9;
}

/* 'No Data' 행 스타일 */
.no-data {
  text-align: center;
  color: #888;
  font-style: italic;
}

/* 플로팅 버튼 스타일 */

/* 플로팅 버튼 스타일 */
.floating-button {
  position: fixed; /* 화면에 고정 */
  bottom: 20px; /* 화면 하단에서 20px */
  right: 20px; /* 화면 오른쪽에서 20px */
  z-index: 1000; /* 다른 요소보다 위에 표시 */
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 50%; /* 원형 버튼 */
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2); /* 그림자 효과 */
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.floating-button:hover {
  background-color: #0056b3;
}

/* 모바일에서도 동일하게 적용 */
@media (max-width: 768px) {
  .floating-button {
    bottom: 15px; /* 모바일 화면 하단 간격 */
    right: 15px; /* 모바일 화면 오른쪽 간격 */
    width: 50px;
    height: 50px;
    font-size: 16px;
  }
}

.available-count {
  font-size: 16px;
  font-weight: bold;
  white-space: nowrap; /* 텍스트 줄바꿈 방지 */
}

.user-info {
  position: fixed;
  top: 10px;
  right: 10px;
  display: flex;
  align-items: center;
}

.user-info .link {
  cursor: pointer;
  color: #007bff;
  font-weight: bold;
  font-size: 22px;
}

.history-table {
  width: 100%;
  border-collapse: collapse;
}

.history-table th,
.history-table td {
  border: 1px solid #ddd;
  padding: 10px;
  text-align: center;
}

.history-table th {
  background-color: #f4f4f4;
  font-weight: bold;
}

.history-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.no-data {
  text-align: center;
  color: #888;
  font-style: italic;
}

.clickable-row {
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.clickable-row:hover {
  background-color: #c1c1c1 !important;
}
</style>
