// src/api/apiService.js
import axios from "axios";

const apiClient = axios.create({
  baseURL:
    process.env.NODE_ENV === "production"
      ? "https://www.autotouch.o-r.kr/api" // 배포 환경 URL
      : "http://localhost:3000/api", // 개발 환경 URL
  headers: {
    "Content-Type": "application/json",
  },
});

export default {
  async doSignUp(data) {
    try {
      const response = await apiClient.post("/auth/signup", data);
      return response.data;
    } catch (error) {
      console.error("Sign-up request failed:", error.message);
      throw new Error("서버에 연결할 수 없습니다. 나중에 다시 시도해주세요.");
    }
  },

  async doLogin(data) {
    try {
      const response = await apiClient.post("/auth/login", data);
      // console.log("로그인 응답 데이터:", response.data);
      return response.data;
    } catch (error) {
      console.error("Login request failed:", error.message);
      throw new Error("서버에 연결할 수 없습니다. 나중에 다시 시도해주세요.");
    }
  },

  async doLogout(data) {
    try {
      const response = await apiClient.post("/auth/logout", data);
      // console.log("로그아웃 응답 데이터:", response.data);
      return response.data;
    } catch (error) {
      console.error("logout request failed:", error.message);
      throw new Error("서버에 연결할 수 없습니다. 나중에 다시 시도해주세요.");
    }
  },

  async getExchangeRates() {
    try {
      const response = await apiClient.post("/exchange/exchange-rates");
      const { exchangeRates, updateTime } = response.data;
      return { exchangeRates, updateTime };
    } catch (error) {
      console.error("환율 데이터를 가져오는 중 오류 발생:", error);
      throw new Error("서버에 연결할 수 없습니다. 나중에 다시 시도해주세요.");
    }
  },

  async getAccommodation(data) {
    try {
      const response = await apiClient.post("/visit/accommodation", data);
      return response.data;
    } catch (error) {
      console.error("Accommodation request failed:", error.message);
      throw new Error("서버에 연결할 수 없습니다. 나중에 다시 시도해주세요.");
    }
  },

  async setProductRequest(formData) {
    try {
      const response = await apiClient.post("/product/request", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return response.data;
    } catch (error) {
      console.error("Product request submission failed:", error.message);
      throw new Error("서버에 연결할 수 없습니다. 나중에 다시 시도해주세요.");
    }
  },

  async getRequestList({ userId, userType }) {
    try {
      // userId를 쿼리 파라미터로 전달
      const response = await apiClient.get(`/product/requestList`, {
        params: { userId, userType }, // 쿼리 파라미터 설정
      });
      return response.data;
    } catch (error) {
      console.error("Failed to fetch product history:", error.message);
      throw new Error("서버에 연결할 수 없습니다. 나중에 다시 시도해주세요.");
    }
  },

  async getProductImages(idRequest, type) {
    try {
      const response = await apiClient.get(`/product/images`, {
        params: { idRequest, type },
      });

      return response.data; // 이미지 경로 리스트를 반환
    } catch (error) {
      console.error("Failed to fetch product images:", error.message);
      throw new Error("이미지 데이터를 가져오는 데 실패했습니다. 나중에 다시 시도해주세요.");
    }
  },

  async getProductImageNames(idRequest) {
    try {
      const response = await apiClient.get(`/product/image-names/${idRequest}`);
      console.log("API 전체 응답:", response); // 전체 응답 출력
      return response.data; // 이미지 이름 리스트를 반환
    } catch (error) {
      console.error("Failed to fetch product image names:", error.message);
      throw new Error("이미지 데이터를 가져오는 데 실패했습니다. 나중에 다시 시도해주세요.");
    }
  },

  async getProductIssue(idRequest) {
    try {
      const response = await apiClient.get(`/product/issue/${idRequest}`);
      return response.data; // 이미지 경로 리스트를 반환
    } catch (error) {
      console.error("Failed to fetch product images:", error.message);
      throw new Error("이미지 데이터를 가져오는 데 실패했습니다. 나중에 다시 시도해주세요.");
    }
  },

  async setProductIssue(formData) {
    try {
      const response = await apiClient.post("/manager/issue", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return response.data;
    } catch (error) {
      console.error("Product request submission failed:", error.message);
      throw new Error("서버에 연결할 수 없습니다. 나중에 다시 시도해주세요.");
    }
  },

  async sendSupportEmail(data) {
    try {
      const response = await apiClient.post("/support/send-email", data);
      console.log("Email sent successfully:", response.data);
      return response.data;
    } catch (error) {
      console.error("Failed to send email:", error.message);
      throw new Error("이메일 전송에 실패했습니다. 나중에 다시 시도해주세요.");
    }
  },

  // PayPal Access Token 생성
  async generateAccessToken() {
    try {
      const response = await apiClient.post("/payment/generate-token");
      return response.data.accessToken; // Access Token 반환
    } catch (error) {
      console.error("Failed to generate access token:", error.message);
      throw new Error("Access Token 생성 중 문제가 발생했습니다.");
    }
  },

  // PayPal Order 생성
  async createOrder(totalPrice, description) {
    try {
      const response = await apiClient.post("/payment/create-order", {
        totalPrice,
        description,
      });

      return response.data; // 서버에서 반환된 결제 데이터 (Order ID 등)
    } catch (error) {
      console.error("Failed to create order:", error.message);
      throw new Error("결제를 생성하는 중 문제가 발생했습니다.");
    }
  },
};
