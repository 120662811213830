<template>
  <div class="payment-page">
    <h1>Quote Request Details</h1>
    <table class="details-table">
      <tbody>
        <tr>
          <th>Date</th>
          <td>{{ formatDateForLocale(dataIssue.date_issue, "en-GB") }}</td>
        </tr>
        <tr>
          <th>Product</th>
          <td>{{ dataIssue.product_fix }}</td>
        </tr>
        <tr>
          <th>Brand</th>
          <td>{{ dataIssue.brand_fix }}</td>
        </tr>
        <tr>
          <th>Unit Price</th>
          <td>$ {{ dataIssue.price_unit }}</td>
        </tr>
        <tr>
          <th>Transportation</th>
          <td>$ {{ dataIssue.price_delivery }}</td>
        </tr>
        <tr>
          <th>Notice</th>
          <td>{{ dataIssue.notice }}</td>
        </tr>
        <tr>
          <th>Image</th>
          <td>
            <div v-for="(image, index) in managerImages" :key="index" class="image-input-group">
              <div class="image-preview" @click="showImageModal(image.url)">
                <img v-if="image.url" :src="image.url" alt="Uploaded Image" />
                <span v-else>No image</span>
                <!-- 모달 -->
                <div v-if="isModalVisible" class="modal-overlay" @click="closeModal">
                  <div class="modal-content" @click.stop>
                    <img :src="modalImage" alt="Full Image" class="modal-img-size" />
                    <!-- X 버튼 추가 -->
                    <button class="modal-close-button" @click="closeModal">X</button>
                  </div>
                </div>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="form-group">
      <label for="quantity">{{ $t("quantity") }} :</label>
      <div class="number-box">
        <button type="button" @click="decreaseQuantity" class="control-button">-</button>
        <input v-model.number="dataIssue.quantity_fix" type="number" id="quantity" class="number-input" min="1" />
        <button type="button" @click="increaseQuantity" class="control-button">+</button>
      </div>
    </div>
    <div>
      <p>총 가격: ${{ totalPrice }}</p>
      <button @click="makePayment">Purchase</button>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import apiService from "@/api/apiService";

export default {
  name: "P_2Payment",
  setup() {
    const router = useRouter();
    const route = useRoute();

    const isLoggedIn = ref(localStorage.getItem("LoggedIn") === "true");
    const isModalVisible = ref(false);
    const modalImage = ref("");

    const managerImages = ref([]);

    // for product_issues table
    const dataIssue = ref({
      idRequest: route.params.id_request || null,
      date_issue: "",
      product_fix: "",
      brand_fix: "",
      quantity_fix: 1,
      price_unit: 0,
      price_delivery: 0,
      notice: "",
      has_image: false,
      image_url: "",
    });

    if (!isLoggedIn.value) {
      router.push("/login");
    }

    const decreaseQuantity = () => {
      if (dataIssue.value.quantity_fix > 1) {
        dataIssue.value.quantity_fix--;
      }
    };

    const increaseQuantity = () => {
      dataIssue.value.quantity_fix++;
    };

    const formatDateForLocale = (dateString, locale = "en-US") => {
      if (!dateString) return "";
      const date = new Date(dateString);
      if (isNaN(date)) return "";
      return new Intl.DateTimeFormat(locale, {
        year: "numeric",
        month: "long",
        day: "numeric",
      }).format(date);
    };

    const getProductIssue = async () => {
      try {
        const data = await apiService.getProductIssue(dataIssue.value.idRequest);
        dataIssue.value = data;
        // console.log("dataIssue.value:", dataIssue.value);
      } catch (error) {
        console.error("Failed to fetch product data:", error);
      }
    };

    const getProductImages = async () => {
      try {
        const response = await apiService.getProductImages(dataIssue.value.idRequest, "manager");
        console.log("getProductImages:", response); // 데이터 확인

        if (Array.isArray(response) && response.length > 0) {
          // 이미지 배열에서 슬롯 번호 추출 및 정렬
          const imagesWithSlot = response.map((image) => {
            const imageName = image.split("/").pop(); // 파일명 추출
            const slotMatch = imageName.match(/manager(\d+)_/); // 슬롯 번호 추출
            const slotNumber = slotMatch ? parseInt(slotMatch[1], 10) : 0;
            return { image, slotNumber };
          });

          // 슬롯 번호를 기준으로 정렬
          imagesWithSlot.sort((a, b) => a.slotNumber - b.slotNumber);

          // 정렬된 배열을 사용하여 `managerImages` 설정
          managerImages.value = imagesWithSlot.map((item) => {
            const imageObject = {
              url: item.image, // 이미지 URL
              fileName: item.image.split("_").pop(), // 파일명 추출 (예: testA.jpg)
              file: null, // 초기 파일은 없음
              fileIndex: item.slotNumber, // 슬롯 번호 사용
            };
            console.log("Adding image object:", imageObject); // 처리된 데이터 확인
            return imageObject;
          });
        } else {
          managerImages.value = [];
          console.warn("No images found or invalid response format");
        }
      } catch (error) {
        console.error("Failed to fetch manager images:", error.message);
        managerImages.value = [];
      }
    };

    const showImageModal = (url) => {
      if (url) {
        modalImage.value = url;
        isModalVisible.value = true;
      }
    };

    const closeModal = () => {
      isModalVisible.value = false;
      modalImage.value = "";
    };

    const totalPrice = computed(() => {
      const unitPrice = parseFloat(dataIssue.value.price_unit || 0); // 단가
      const quantity = parseInt(dataIssue.value.quantity_fix || 0, 10); // 수량
      const deliveryPrice = parseFloat(dataIssue.value.price_delivery || 0); // 배송비

      // 디버깅: 값 확인
      console.log("unitPrice:", unitPrice);
      console.log("quantity:", quantity);
      console.log("deliveryPrice:", deliveryPrice);
      // 총 금액 계산
      const total = unitPrice * quantity + deliveryPrice;
      console.log("total:", total);

      // 모든 값을 더해서 총 금액 반환
      return total;
    });

    const fetchAccessToken = async () => {
      try {
        const accessToken = await apiService.generateAccessToken();
        console.log("Access Token:", accessToken); // 필요한 경우 저장하거나 사용
        return accessToken; // Access Token 반환
      } catch (error) {
        console.error("Access Token 생성 실패:", error.message);
        throw new Error("Access Token을 가져오는 중 오류가 발생했습니다.");
      }
    };

    const makePayment = async () => {
      try {
        // Access Token 생성
        await fetchAccessToken();

        // 총 금액 계산
        const totalPriceValue = parseFloat(totalPrice.value);
        console.log("totalPriceValue:", totalPriceValue); // 디버깅: 계산된 총 금액

        // 총 금액 계산 및 검증
        if (isNaN(totalPriceValue) || totalPriceValue <= 0) {
          throw new Error("유효하지 않은 총 결제 금액입니다.");
        }

        // 상품 설명
        const description = `Purchase for ${dataIssue.value.product_fix}`;

        // API 호출
        const result = await apiService.createOrder(totalPriceValue, description);

        if (result.id) {
          const approvalUrl = result.links.find((link) => link.rel === "approve")?.href;
          if (approvalUrl) {
            window.location.href = approvalUrl;
          } else {
            alert("결제 승인 URL을 찾을 수 없습니다.");
          }
        } else {
          alert("결제 생성 실패: " + (result.error || "Unknown error"));
        }
      } catch (error) {
        console.error("결제 중 오류 발생:", error.message);
        alert("결제 중 오류가 발생했습니다.");
      }
    };

    onMounted(() => {
      getProductIssue();
      getProductImages();
    });

    return {
      dataIssue,
      managerImages,
      isModalVisible,
      modalImage,
      totalPrice,
      decreaseQuantity,
      increaseQuantity,
      formatDateForLocale,
      showImageModal,
      closeModal,
      makePayment,
    };
  },
};
</script>

<style scoped>
.payment-page {
  text-align: center;
}

.form-group {
  display: flex;
  flex-direction: column;
  margin-top: 25px;
  margin-bottom: 25px;
  width: 100%;
  max-width: 80%;
}

.number-input {
  -moz-appearance: textfield; /* Firefox */
  -webkit-appearance: none; /* Safari, Chrome */
  appearance: none; /* 최신 브라우저 */
  width: 50px; /* 가로 크기 */
  height: 20px; /* 세로 크기 */
  padding: 5px; /* 내부 여백 */
  font-size: 16px; /* 글자 크기 */
}

.number-input::-webkit-inner-spin-button,
.number-input::-webkit-outer-spin-button {
  -webkit-appearance: none; /* Chrome, Safari */
  margin: 0;
}

.details-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
  border-spacing: 0;
  margin: 0; /* 외부 여백 제거 */
  padding: 0; /* 내부 여백 제거 */
}

.details-table th,
.details-table td {
  border: 1px solid #ddd;
  text-align: left;
  vertical-align: middle;
}

.details-table th {
  background-color: #f2f2f2;
  width: 100px;
  text-align: right;
  padding: 8px;
}

.details-table td {
  padding: 10px;
}
.details-table th:first-child {
  padding-left: 0; /* 첫 번째 열의 왼쪽 여백 제거 */
}
.image-input-group {
  margin-bottom: 8px;
}

/* 이미지 미리보기 */
.image-preview {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 150px;
  border: none;
  border-radius: 4px;
  overflow: hidden;
}

.image-preview img {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
}

.modal-img-size {
  max-width: 70vw !important;
  max-height: 70vh !important;
}
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  cursor: pointer;
}

.modal-content {
  max-width: 90%;
  max-height: 90%;
  position: relative;
}

.modal-image {
  width: 100%;
  height: auto;
  border-radius: 4px;
  cursor: default;
}

.modal-close-button {
  z-index: 1002; /* X 버튼은 모달 내용보다 위 */
  background-color: #007bff; /* 테스트용 배경색 */
  color: white; /* 글자 색상 */
  position: absolute; /* 위치 고정 */
  top: 10px; /* 모달 상단에서 10px 아래 */
  right: 10px; /* 모달 오른쪽에서 10px 왼쪽 */
  border: none;
  font-size: 20px; /* 버튼 크기 */
  font-weight: bold;
  cursor: pointer; /* 클릭 가능 */
  z-index: 1002; /* 다른 요소보다 앞에 표시 */
  width: 40px; /* 테스트용 버튼 너비 */
  height: 40px; /* 테스트용 버튼 높이 */
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-close-button:hover {
  color: #ccc; /* 호버 시 색상 변경 */
}

button {
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  padding: 10px 20px;
}

button:hover {
  opacity: 0.9;
}

button:hover:enabled {
  background-color: #0056b3;
}

button:disabled {
  padding: 10px 20px;
  background-color: #ccc;
  cursor: not-allowed;
}
</style>
