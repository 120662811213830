<template>
  <div class="privacy-policy-page">
    <h1>{{ $t("privacyPolicy") }}</h1>
    <div class="privacy-content">
      <p>본 개인정보 처리방침은 (주) 무한시스템즈가 제공하는 서비스와 관련하여 고객의 개인정보를 보호하고, 관련 법령에 따라 적절히 관리하기 위해 작성되었습니다.</p>
      <h2>1. 개인정보 수집 목적</h2>
      <p>회사는 다음과 같은 목적을 위해 개인정보를 수집합니다:</p>
      <ul>
        <li>서비스 제공 및 회원 관리</li>
        <li>고객 상담 및 문의 응대</li>
        <li>마케팅 및 광고 활용</li>
      </ul>
      <h2>2. 수집하는 개인정보 항목</h2>
      <p>회사는 서비스 이용을 위해 다음과 같은 개인정보를 수집할 수 있습니다:</p>
      <ul>
        <li>이름, 연락처, 이메일</li>
        <li>로그 기록, IP 주소</li>
      </ul>
      <h2>3. 개인정보 보유 기간</h2>
      <p>회사는 법령에서 정한 보유 기간에 따라 개인정보를 보관하며, 보유 기간이 종료된 경우 해당 정보를 지체 없이 파기합니다.</p>
      <h2>4. 개인정보 제공</h2>
      <p>회사는 원칙적으로 고객의 동의 없이 개인정보를 제3자에게 제공하지 않습니다.</p>
      <p>
        자세한 문의사항은 <a @click="goToFaq">{{ $t("faqSupport") }}</a
        >를 참조하세요.
      </p>
    </div>
  </div>
</template>

<script>
import { useRouter } from "vue-router";

export default {
  name: "PrivacyPolicy",
  setup() {
    const router = useRouter();

    const goToFaq = () => {
      router.push("/faq-support");
    };

    return {
      goToFaq,
    };
  },
};
</script>

<style scoped>
.privacy-policy-page {
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
  line-height: 1.6;
}

h1 {
  font-size: 28px;
  margin-bottom: 20px;
}

h2 {
  font-size: 20px;
  margin-top: 20px;
}

.privacy-content p {
  margin-bottom: 15px;
}

.privacy-content ul {
  list-style-type: disc;
  margin-left: 20px;
}

a {
  color: #007bff;
  text-decoration: none;
  cursor: pointer;
}

a:hover {
  text-decoration: underline;
}
</style>
