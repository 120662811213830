<template>
  <div class="quote-request-page">
    <div class="user-info">
      <span v-if="isLoggedIn" class="link" @click="goToUserProfile">{{ nickname }}</span>
    </div>

    <div class="title">
      <h1>견적 요청 리스트</h1>
    </div>

    <table class="history-table">
      <thead>
        <tr>
          <th>순번</th>
          <th>요청 날짜</th>
          <th>상태</th>
          <th>상품명</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(request, index) in requests" :key="request.id_request" @click="navigateByStatus(request)" class="clickable-row">
          <td>{{ index + 1 }}</td>
          <td>{{ formatDate(request.date_request) }}</td>
          <td>{{ getStatusText(request.status) }}</td>
          <td>{{ request.product }}</td>
        </tr>
        <tr v-if="requests.length === 0">
          <td colspan="6" class="no-data">{{ $t("noRequests") }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import apiService from "@/api/apiService";
import { doLogout } from "@/components/authentication/AuthService";

export default {
  name: "M_0Main",
  setup() {
    const router = useRouter();
    const { t } = useI18n();
    const requests = ref([]);
    const isLoggedIn = ref(localStorage.getItem("LoggedIn") === "true");
    const nickname = ref(localStorage.getItem("Nickname"));

    if (!isLoggedIn.value) {
      router.push("/login");
    }

    const goToUserProfile = () => {
      router.push("/user-profile");
    };

    const checkUserType = () => {
      const userType = localStorage.getItem("UserType");
      console.log("@@@@@@@@@@@@@@ userType : ", userType);
      if (userType !== "manager") {
        alert("권한이 없습니다. 메인 페이지로 이동합니다.");
        doLogout();
        router.push("/");
        return false;
      }
      return true;
    };

    const getRequestList = async () => {
      if (!checkUserType()) return; // 권한 확인 후 요청 수행

      try {
        const userId = localStorage.getItem("UserId");
        if (!userId) {
          throw new Error("User ID not found.");
        }

        const userType = localStorage.getItem("UserType");
        if (!userType) {
          throw new Error("User Type not found.");
        }

        const data = await apiService.getRequestList({ userId, userType });
        requests.value = data;
      } catch (error) {
        console.error("Failed to fetch quote requests:", error);
      }
    };

    const formatDate = (date) => {
      const options = { month: "short", day: "numeric" }; // 월, 일
      return new Date(date).toLocaleDateString("en-US", options);
    };

    const getStatusText = (status) => {
      const statusMap = {
        1: t("status_requested"),
        2: t("status_issued"),
        3: t("status_paid"),
        4: t("status_canceled"),
        5: t("status_expired"),
      };
      return statusMap[status] || t("unknown");
    };

    const navigateByStatus = (request) => {
      console.log("navigateByStatus request:", request);
      router.push({
        path: `/manager/issue/${request.id_request}`,
        query: {
          product: request.product,
          quantity: request.quantity,
          brand: request.brand,
          referenceLink: request.link || "",
          hasImage: request.has_image ? 1 : 0,
          extraRequest: request.extra_request || "",
        },
      });
    };

    onMounted(() => {
      getRequestList();
    });

    return { isLoggedIn, requests, nickname, goToUserProfile, formatDate, getStatusText, navigateByStatus };
  },
};
</script>

<style scoped>
.quote-request-page {
  padding: 20px;
}

.title {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 0;
  margin-top: 0;
  min-height: 0;
}

.user-info {
  position: fixed;
  top: 10px;
  right: 10px;
  display: flex;
  align-items: center;
}

.user-info .link {
  cursor: pointer;
  color: #007bff;
  font-weight: bold;
  font-size: 22px;
}

.history-table {
  width: 100%;
  border-collapse: collapse;
}

.history-table th,
.history-table td {
  border: 1px solid #ddd;
  padding: 10px;
  text-align: center;
}

.history-table th {
  background-color: #f4f4f4;
  font-weight: bold;
}

.history-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.no-data {
  text-align: center;
  color: #888;
  font-style: italic;
}

.clickable-row {
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.clickable-row:hover {
  background-color: #c1c1c1 !important;
}
</style>
